import "owl.carousel";
function impressionsSlider() {
  let owlImpression = jQuery(".impressions-slider-inner");
  //var itemCount = jQuery(owl).find('.stage').length;

  owlImpression.owlCarousel({
    //animateOut: 'fadeOut',
    nav: true,
    navText: [
      '<span class="owl-carousel-arrow"><img src="/wp-content/uploads/arrow-left.svg" alt="Creartico Slider Icon"/></span>',
      '<span class="owl-carousel-arrow"><img src="/wp-content/uploads/arrow-right.svg" alt="Creartico Slider Icon"/></span>',
    ],
    mouseDrag: false,
    touchDrag: true,
    loop: true,
    dots: false,
    margin: 10,
    stagePadding: 50,
    autoplay: true,
    smartSpeed: 750,
    stagePadding: 0,
    items: 3,
    lazyLoad: true,
    responsive: {
      0: {
        items: 1,
      },
      992: {
        items: 2,
      },
      1440: {
        items: 3,
      },
    },
  });
}

export { impressionsSlider };


function impressionFancyBoxCaption() {
  $( '.impressions-fancybox' ).fancybox({
    selector : '.impressions-slider-item:visible > a',
    infobar : false,
    toolbar: false,
    arrows: false,
    caption : function( instance, item ) {
      var caption = $(this).data('caption') || '';
      return (caption)
    }
  });
}

export { impressionFancyBoxCaption };
