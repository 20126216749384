function logoSizeChanger() {
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll >= 50) {
      $(".logo-container").addClass("logo-container-smaller");
    } else {
      $(".logo-container").removeClass("logo-container-smaller");
    }
  });
}

export { logoSizeChanger };
