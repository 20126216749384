function teamFancyBoxCaption() {
    $( '.team-fancybox' ).fancybox({
      selector : '.team-slider-image',
      infobar : false,
      toolbar: true,
      arrows: false,
      caption : function( instance, item ) {
        var caption = $(this).data('caption') || '';
        return (caption)
      }
    });
  }

  export { teamFancyBoxCaption };
