function toggleNavigation() {
  $(".navbar-toggler").click(function (event) {
    event.preventDefault();
    $(".main-menu-class").toggleClass("main-menu-show");
    $(".navbar-toggler").toggleClass("collapsed");
    $("body").toggleClass("body_overlay");
    return false;
  });


}
export { toggleNavigation };


function toggleHamburger() {
  $(".navbar-toggler").click(function () {
    $(".hamburger").toggleClass("is-active");
    $(".nav-container").toggleClass("nav-container_open");
    $(".hamburger-inner-end-button").toggleClass("hamburger-inner-end-button-inactive");
    $("body").toggleClass("body_overlay");
    return false;
  });
}
export { toggleHamburger };


function navCloserOnClick() {
  $(document).click(function () {
    if ($("body").hasClass("body_overlay")) {
      $(".navbar-toggler").click();

    }
  });
}

export { navCloserOnClick };
