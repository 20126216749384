import "owl.carousel";
function cardsSlider() {
  let owlCardsSlider = jQuery(".cards-slider-inner");

  owlCardsSlider.owlCarousel({
    nav: false,
    navText: [
      '<span class="owl-carousel-pfeil"><img src="/wp-content/uploads/arrow-left.svg" alt="Creartico Slider Icon"/></span>',
      '<span class="owl-carousel-pfeil"><img src="/wp-content/uploads/arrow-right.svg" alt="Creartico Slider Icon"/></span>',
    ],
    mouseDrag: false,
    touchDrag: false,
    loop: true,
    dots: true,
    margin: 16,
    autoplay: false,
    smartSpeed: 1000,
    items: 1,
    lazyLoad: true,
    responsive: {
      0: {
        nav: true,
        dots: false,
        autoHeight: true
      },
      768: {
        nav: false,
        dots: true,
        autoHeight: false
      }
    },
  });


  $(window).on('resize', function(){
    owlCardsSlider.trigger('refresh.owl.carousel');
    console.log('refresh');
  });
}
export { cardsSlider };

function cardsSliderDotsIdCounter() {
  $(document).ready(function () {
    let elem = document.querySelectorAll(".owl-dots > button");
    let j = 0;
    for (let i = 0; i < elem.length; i++) {
      //set id value
      elem[i].id = "owl-dot-id-" + j;
      j++;
    }
  });
}

export { cardsSliderDotsIdCounter };

function cardsSliderImageClick() {

  $(".cards-slider-inner .owl-item").each(function(){

    let card = $(this).find(".card-slider-card-item-image");

    let cardID = card.attr("id");
    cardID = cardID.replace(/[^0-9.]/g, "");

    $(this).click(function() {
      console.log(cardID);
      $("#owl-dot-id-" + cardID).click();
    });

  });

}
export { cardsSliderImageClick };
