/* Third-Party */
import jQuery from "jquery";
import "@fancyapps/fancybox";
import "bootstrap";

/* Layout-Parts */
import { lazyload } from "./globals/lazyload";
import { sidebarOpener } from "./layout/sidebarOpener";
import { toggleNavigation } from "./layout/navigation";
import { toggleHamburger } from "./layout/navigation";
import { navCloserOnClick } from "./layout/navigation";
import { logoSizeChanger } from "./layout/logoSizeChanger";
//import { slider } from './blocks/slider';
//import { accordion } from './blocks/accordion';

/* Blocks */
import { impressionsSlider } from "../../blocks/impressions-slider/script";
import { cardsSlider } from "../../blocks/cards-slider/script";
import { cardsSliderTextHidden } from "../../blocks/cards-slider/script";
import { cardsSliderDotsIdCounter } from "../../blocks/cards-slider/script";
import { cardsSliderImageClick } from "../../blocks/cards-slider/script";
import { galleryIsotopeSorter } from "../../blocks/gallery-sort/script";
import { galleryCategoryToggle } from "../../blocks/gallery-sort/script";
import { galleryFancyBoxCaption } from "../../blocks/gallery-sort/script";
import { impressionFancyBoxCaption } from "../../blocks/impressions-slider/script";
import { teamFancyBoxCaption } from "../../blocks/team/script";

jQuery(document).ready((_) => {
  lazyload();
  toggleNavigation();
  toggleHamburger();
  navCloserOnClick();
  impressionsSlider();
  sidebarOpener();
  logoSizeChanger();
  cardsSlider();
  // cardsSliderTextHidden();
  cardsSliderDotsIdCounter();
  cardsSliderImageClick();
  galleryIsotopeSorter();
  galleryCategoryToggle();
  galleryFancyBoxCaption();
  impressionFancyBoxCaption();
  teamFancyBoxCaption();

});
